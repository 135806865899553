@media screen and (max-width: 430px) {
  .intro h1 {
    text-align: center;
    font-size: 42px;
  }

  #services ol {
    padding: 0 !important;
  }

  #services ol li span {
    padding: 10px;
    width: fit-content;
    display: flex;
  }

  #partners>div {
    margin: 0px 20px !important;
  }
}